import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from 'reactstrap';

const AddEquipmentModal = ({ isOpen, toggle, handleAddEquipment, newEquipment, setNewEquipment, alert, message }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} centered className="modal-lg">
            <ModalHeader>
                Add New Equipment
            </ModalHeader>
            <ModalBody>
                {alert && alert === "danger" ? (<Alert color={alert}>{message}</Alert>) : null}
                <form onSubmit={handleAddEquipment}>
                <div className="mb-3">
                        <label htmlFor="itemName" className="form-label">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="itemName"
                            name="itemName"
                            value={newEquipment.itemName} // Assurez-vous que newEquipment est défini
                            onChange={(e) => setNewEquipment({ ...newEquipment, itemName: e.target.value })}
                            required
                        />
                    </div>
                  
                    <div className="mb-3">
                        <label htmlFor="size" className="form-label">Size</label>
                        <input
                            type="text"
                            className="form-control"
                            id="size"
                            name="size"
                            value={newEquipment.size}
                            onChange={(e) => setNewEquipment({ ...newEquipment, size: e.target.value })}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="unit" className="form-label">Unit</label>
                        <input
                            type="text"
                            className="form-control"
                            id="unit"
                            name="unit"
                            value={newEquipment.unit}
                            onChange={(e) => setNewEquipment({ ...newEquipment, unit: e.target.value })}
                            required
                        />
                    </div>
                    <ModalFooter>
                        <Button type="button" color="secondary" onClick={toggle}>Cancel</Button>
                        <Button type="submit" color="primary">Add Equipment</Button>
                    </ModalFooter>
                </form>
            </ModalBody>
        </Modal>
    );
};

export default AddEquipmentModal; 