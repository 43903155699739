import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from 'reactstrap';

const AddIngredientModal = ({ isOpen, toggle, handleAddIngredient, newIngredient, setNewIngredient, alert, message }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} centered className="modal-lg">
            <ModalHeader>
                Add New Ingredient
            </ModalHeader>
            <ModalBody>
                {alert && alert === "danger" ? (<Alert color={alert}>{message}</Alert>) : null}
                <form onSubmit={handleAddIngredient}>
                    <div className="mb-3">
                        <label htmlFor="drugName" className="form-label">Drug Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="drugName"
                            name="drugName"
                            value={newIngredient.drugName}
                            onChange={(e) => setNewIngredient({ ...newIngredient, drugName: e.target.value })}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="concentration" className="form-label">Concentration/Strength</label>
                        <input
                            type="text"
                            className="form-control"
                            id="concentration"
                            name="concentration"
                            value={newIngredient.concentration}
                            onChange={(e) => setNewIngredient({ ...newIngredient, concentration: e.target.value })}
                            
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="drugForm" className="form-label">Drug Form</label>
                        <input
                            type="text"
                            className="form-control"
                            id="drugForm"
                            name="drugForm"
                            value={newIngredient.drugForm}
                            onChange={(e) => setNewIngredient({ ...newIngredient, drugForm: e.target.value })}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="size" className="form-label">Size</label>
                        <input
                            type="text"
                            className="form-control"
                            id="size"
                            name="size"
                            value={newIngredient.size}
                            onChange={(e) => setNewIngredient({ ...newIngredient, size: e.target.value })}
                           
                        />
                    </div>
                    <ModalFooter>
                        <Button type="button" color="secondary" onClick={toggle}>Cancel</Button>
                        <Button type="submit" color="primary">Add Ingredient</Button>
                    </ModalFooter>
                </form>
            </ModalBody>
        </Modal>
    );
};

export default AddIngredientModal; 