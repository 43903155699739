import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Form,
  Col,
  Row,
  CardSubtitle,
  Alert,
  Container,
  Progress,
} from "reactstrap";
//import './style.css';
import Breadcrumbs from "../../../components/Common/Breadcrumb.js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate } from "react-router-dom";
import Dropzone from "react-dropzone";
import axios from "axios";
import { BACKEND_URL } from "../../../config/const.js";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { countries, StateProvince, StateProvinceUS } from "./Constans.js"; // Adjust the path if necessary
import "./Styles.css";
const CreateNewGroup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const now = new Date();
  document.title = t("createGroupTitle");
  const [country, setCountry] = useState("CA");
  const [province, setProvince] = useState("QC");
  const [timeZone, setTimeZone] = useState("America/Toronto");
  const [provinces, setProvinces] = useState([]);
  const [timeZones, setTimeZones] = useState([]);
  const [timeFormat, setTimeFormat] = useState("H:i"); // Default format
  const [currentTime, setCurrentTime] = useState(
    moment.tz(now, timeZone).format("HH:mm")
  );
  const [selectedFiles, setSelectedFiles] = useState(null);

  const [officeType, setOfficeType] = useState("pharmacy");
  const [emails, setEmails] = useState([]);
  const [quessCompounding, setQuessCompounding] = useState(false);
  const [emailToAdd, setEmailToAdd] = useState("");
  const [emailLanguage, setEmailLanguage] = useState(
    localStorage.getItem("lang")
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [addGroupError, setAddGroupError] = useState(null);
  const [logoNameError, setLogoNameError] = useState(null);
  const [addressError, setAddreessError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    separator: "/",
    dateformat: "Y/m/d",
    adress: "",
    country: country,
    timezone: timeZone,
    hourformat: timeFormat,
    state: province,
    officeType: officeType,
    shortName: "",
    emails: emails,
    File: "",
  });
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(
        moment.tz(now, timeZone).format(timeFormatToMomentFormat(timeFormat))
      );
    }, 60000); // Update every minute
    return () => clearInterval(interval);
  }, [timeFormat, timeZone]);
  useEffect(() => {
    setCurrentTime(
      moment.tz(now, timeZone).format(timeFormatToMomentFormat(timeFormat))
    );
  }, [timeZone]);

  // Function to handle time format change
  const handleChangeTimeFormat = (e) => {
    const selectedFormat = e.target.value;
    const now = new Date();
    setTimeFormat(selectedFormat); // Update the selected format
    setCurrentTime(
      moment.tz(now, timeZone).format(timeFormatToMomentFormat(selectedFormat))
    ); // Reformat time immediately
  };

  // Helper function to map select value to moment.js format
  const timeFormatToMomentFormat = (timeFormat) => {
    switch (timeFormat) {
      case "H:i":
        return "HH:mm"; // 24-hour format without seconds
      case "h:i A":
        return "h:mm A"; // 12-hour format with AM/PM
      case "H\\hi":
        return "HH[h]mm"; // Custom format with "h" as literal
      case "h\\hi A":
        return "h[h]mm A"; // Custom format with AM/PM
      default:
        return "HH:mm";
    }
  };
  const getTimezonesByCountry = (countryCode) => {
    // Get all timezones for the given country code
    const timezones = moment.tz.zonesForCountry(countryCode);
    const now = new Date();

    // Map over each timezone to get the current time
    return timezones.map((timezone) => {
      return {
        timezone,
        currentTime: moment
          .tz(now, timezone)
          .format(timeFormatToMomentFormat(timeFormat)), // Format the current time
      };
    });
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic regex for email validation
    return re.test(email);
  };
  useEffect(() => {
    if (country == "US") {
      setProvinces(StateProvinceUS);
      const timeZoensForUS = getTimezonesByCountry("US");
      setTimeZones(timeZoensForUS);
      setTimeZone("America/New_York");
      setProvince("NY");
    } else {
      setProvinces(StateProvince);
      const timeZonesForCanada = getTimezonesByCountry("CA");
      setTimeZones(timeZonesForCanada);
      setTimeZone("America/Toronto");
      setProvince("QC");
    }
  }, [country]);

  const checkGroupName=()=> {
    if(formData.name=="")
      {
        setNameError(true)
       return false
      } 
      else {
        setNameError(null)
        return true
      }
  }
  const checkAddress=()=> {
    if(formData.name=="")
      {
        setAddreessError(true)
       return false
      } 
      else {
        setAddreessError(null)
        return true
      }
  }
  const checkPhone=()=> {
   
    if(formData.shortName=="")
      {
        setPhoneError(true)
       return false
      }  
      else {
        setPhoneError(null)
        return true
      }
  }
  const checkLogoName=()=> {
   
    if(formData.shortName=="")
      {
        setLogoNameError(true)
       return false
      }  
      else {
        setLogoNameError(null)
        return true
      }
  }
  
 
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const groupNameValid= checkGroupName()
    const logoNameValid= checkLogoName()
    const isValidPhone= checkPhone();
    const isValidAddress = checkAddress();
    if(!groupNameValid || !logoNameValid || !isValidPhone || !isValidAddress)
    {
     
      setAddGroupError({text:t('failureAddGroup'), type:"error"});
      setTimeout(() => {
        setAddGroupError(null)
        setLoading(null)
      }, 2500);
    } 
    else {
      const configUpload = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data"
        },
      };
      const groupData=new FormData()
      groupData.append("name",formData.name)
      groupData.append("phone",formData.phone)
      groupData.append("address",formData.adress)
      groupData.append("timezone",timeZone)
      groupData.append("hourformat",timeFormat)
      groupData.append("dateformat","Y/m/d")
      groupData.append("country",country)
      groupData.append("state",province)
      groupData.append("officeType",officeType)
      groupData.append("shortName",formData.shortName)
      groupData.append("separator",formData.separator)
      groupData.append("emails", JSON.stringify(emails)); // Stringify the array of objects
      groupData.append("quessCompounding",quessCompounding)
      groupData.append("File",selectedFiles)
      const response = await axios.post(`${BACKEND_URL}api/clients/new`,groupData,configUpload)
      if(response.code===200)
      {
        setAddGroupError({text:t('successAddGroup'), type:"success"});
        setTimeout(() => {
          setAddGroupError(null)
          setLoading(null)
          navigate("/groups-management")
        }, 2500);
      }
      else  {
     
        setAddGroupError({text:t('failureAddGroup'), type:"error"});
        setTimeout(() => {
          setAddGroupError(null)
          setLoading(null)
        }, 2500);
      } 

    }
  };



  const goBack = () => {
    navigate("/groups-management"); 
  };
  const handleEmailAdd = () => {
    if (!validateEmail(emailToAdd)) {
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
      const emailToAddData = {
        email: emailToAdd,
        language: emailLanguage,
      };
      setEmails([...emails, emailToAddData]);
      setEmailToAdd("");
      setEmailLanguage(localStorage.getItem("lang"));
    }
  };
  const handleDeleteEmail = (email) => {
    const updatedEmails = emails.filter((em) => em != email);
    setEmails(updatedEmails);
  };

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    // Store only the first file (for single file upload)
    setSelectedFiles(files[0]);
  }
  const handleRemoveFile = () => {
    // Remove the selected file from the state
    setSelectedFiles(null);
  };
  const handleToggle = () => {
    
    setQuessCompounding((prevState) => {
      return !prevState;
    });
  };
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const handlePhoneInput = (value) => {
    // Remove all non-digit characters
    const cleaned = value.replace(/\D/g, "").slice(0, 10); // Limit to 10 digits
  
    // Format the input as (XXX) XXX-XXXX
    const formatted = cleaned
      .replace(/^(\d{3})(\d{0,3})(\d{0,4})$/, (_, p1, p2, p3) => {
        let result = `(${p1}`;
        if (p2) result += `) ${p2}`;
        if (p3) result += `-${p3}`;
        return result;
      });
  
    // Update formData with the formatted phone number
    setFormData({ ...formData, phone: formatted });
  };
  console.log(formData.phone);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("groupsManagement")}
            breadcrumbItem={t("createGroup")}
          />
          {loading ? (
            <Alert color="info">{t('loadingSubmission')}</Alert>
          ) : error ? (
            <Alert color="danger">{error}</Alert>
          ) : null}
          <div className=" mb-4">
            <div
              style={{
                margin: "1%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                className="btn btn-primary"
                title={t("goBack")}
                onClick={() => goBack()}
              >
                <i className="fas fa-arrow-left"></i>
              </button>
            </div>

            <Form onSubmit={handleSubmit}>
              <Row>
                <Col lg={12}>
                  <Card className="card-bordred">
                    <h5 className="card-header">{t("groupInfos")}</h5>
                    <CardBody>
                      <Card className="card-bordred">
                        <CardBody>
                          <Row className="mt-3">
                            <Col lg={12}>
                              <label className="form-label">
                                {t("groupName")}
                              </label>
                              <input
                                name="groupName"
                                className="form-control"
                                type="text"
                                placeholder={t("groupName")}
                                value={formData.name}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    name: e.target.value,
                                  })
                                }
                              />
                              {nameError && (
                                <code className="text-danger">
                                  {" "}
                                  {t("groupNameRequired")}
                                </code>
                              )}
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col lg={12}>
                              <label className="form-label">{t("phone")}</label>
                              <input
                                name="phone"
                                className="form-control"
                                type="text" // Use text instead of number for formatting
                                placeholder={t("phone")} // e.g., "Enter phone number"
                                value={formData.phone}
                                onChange={(e) => handlePhoneInput(e.target.value)}
                              />
                                {phoneError && (
                                <code className="text-danger">
                                  {" "}
                                  {t("phoneRequired")}
                                </code>
                              )}
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col lg={12}>
                              <label className="form-label">
                                {t("adress")}
                              </label>
                              <input
                                name="adress"
                                className="form-control"
                                type="text"
                                placeholder={t("adress")}
                                value={formData.adress}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    adress: e.target.value,
                                  })
                                }
                              />
                                {addressError && (
                                <code className="text-danger">
                                  {" "}
                                  {t("addressRequireed")}
                                </code>
                              )}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card className="card-bordred">
                        <CardBody>
                          <Row className="mt-3">
                            <Col lg={4}>
                              <label className="form-label">
                                {t("country")}
                              </label>
                              <select
                                className="form-select"
                                onChange={(e) => setCountry(e.target.value)}
                              >
                                <option
                                  aria-selected="true"
                                  role="option"
                                  value="0"
                                  disabled
                                >
                                  {t("selectCountry")}
                                </option>
                                {countries.map((country) => (
                                  <option
                                    key={country.value}
                                    value={country.value}
                                  >
                                    {t(country.label)}
                                  </option>
                                ))}
                              </select>
                            </Col>
                            <Col lg={4}>
                              <label className="form-label">
                                {t("stateProvince")}
                              </label>
                              <select
                                className="form-select"
                                value={province}
                                onChange={(e) => setProvince(e.target.value)}
                              >
                                <option
                                  aria-selected="true"
                                  role="option"
                                  value="0"
                                  disabled
                                >
                                  {t("selectProvince")}
                                </option>
                                {provinces.map((prov) => (
                                  <option key={prov.value} value={prov.value}>
                                    {prov.label}
                                  </option>
                                ))}
                              </select>
                            </Col>
                            <Col lg={4}>
                              <label className="form-label">
                                {t("timeZone")}
                              </label>
                              <select
                                className="form-select"
                                value={timeZone}
                                onChange={(e) => setTimeZone(e.target.value)}
                              >
                                <option
                                  aria-selected="true"
                                  role="option"
                                  value="0"
                                  disabled
                                >
                                  {t("selecttimeZone")}
                                </option>
                                {timeZones.map((timeZone) => (
                                  <option
                                    key={timeZone.timezone}
                                    value={timeZone.timezone}
                                  >
                                    {timeZone.timezone +
                                      " : " +
                                      timeZone.currentTime}
                                  </option>
                                ))}
                              </select>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col lg={4}>
                              <label className="form-label">
                                {t("dateFormat")}
                              </label>
                              <select
                                className="form-select"
                                value={"yyyy/mm/dd"}
                                disabled
                              >
                                <option
                                  aria-selected="true"
                                  role="option"
                                  value="yyyy/mm/dd"
                                  disabled
                                >
                                  yyyy/mm/dd
                                </option>
                              </select>
                            </Col>
                            <Col lg={4}>
                              <label className="form-label">
                                {t("separateur")}
                              </label>
                              <select
                                className="form-select"
                                value={"/"}
                                disabled
                              >
                                <option
                                  aria-selected="true"
                                  role="option"
                                  value="/"
                                  disabled
                                >
                                  /
                                </option>
                              </select>
                            </Col>
                            <Col lg={4}>
                              <label className="form-label">
                                {t("timeFormat")}
                              </label>
                              <select
                                className="form-select"
                                value={timeFormat}
                                onChange={handleChangeTimeFormat}
                              >
                                <option
                                  aria-selected="true"
                                  role="option"
                                  value="0"
                                  disabled
                                >
                                  {t("selecttimeZone")}
                                </option>
                                <option value="H:i">
                                  {moment.tz(now, timeZone).format(timeFormatToMomentFormat("H:i"))} (24h){" "}
                                </option>
                                <option value="h:i A">
                                  {moment.tz(now, timeZone).format(timeFormatToMomentFormat("h:i A"))} (12h){" "}
                                </option>
                                <option value="H\hi">
                                  {moment.tz(now, timeZone).format(timeFormatToMomentFormat("H\\hi"))} (24h){" "}
                                </option>
                                <option value="h\hi A">
                                  {moment.tz(now, timeZone).format(timeFormatToMomentFormat("h\\hi A"))} (12h)
                                </option>
                              </select>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      <Row className="mt-3 ">
                        <Col lg={ emails.length>0?8:12}>
                          <Card className="card-bordred">
                            <CardBody>
                              <Row className="mt-3">
                                <Col lg={12}>
                                  <label className="form-label">
                                    {t("contact")}
                                  </label>
                                  <button
                                    className="btn btn-primary"
                                    style={{ marginLeft: "10%" }}
                                     type="button"
                                    onClick={() => handleEmailAdd()}
                                  >
                                    + {t("add")}
                                  </button>
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col lg={6}>
                                  <label className="form-label">
                                    {t("email")}
                                  </label>
                                  <input
                                    name="email"
                                    className="form-control"
                                    type="email"
                                    placeholder={t("email")}
                                    value={emailToAdd}
                                    onChange={(e) =>
                                      setEmailToAdd(e.target.value)
                                    }
                                  />
                                  {emailError && (
                                    <code className="text-danger">
                                      {" "}
                                      {t("errorEmail")}
                                    </code>
                                  )}
                                </Col>
                                <Col lg={6}>
                                  <label className="form-label">
                                    {t("language")}
                                  </label>
                                  <select
                                    className="form-select"
                                    value={emailLanguage}
                                    onChange={(e) =>
                                      setEmailLanguage(e.target.value)
                                    }
                                  >
                                    <option
                                      aria-selected="true"
                                      role="option"
                                      value="0"
                                      disabled
                                    >
                                      {t("selectLanguage")}
                                    </option>
                                    <option value="fr">{t("fr")}</option>
                                    <option value="en">{t("en")}</option>
                                  </select>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        {emails.length>0 && (
                          <Col lg={4}>
                          <Card className="card-bordred ">
                            <CardBody>
                              {emails.map((email) => (
                                <Row>
                                  <Col lg={7}>
                                    <p> {email.email}</p>
                                  </Col>
                                  <Col lg={3}>
                                    <p>{t(email.language)}</p>
                                  </Col>
                                  <Col lg={2}>
                                    <i
                                      className="fas fa-times-circle delete-button"
                                      onClick={() => handleDeleteEmail(email)}
                                    ></i>
                                  </Col>
                                </Row>
                              ))}
                            </CardBody>
                          </Card>
                        </Col>
                        )}
                        
                      </Row>
                      <Row className="mt-3 ">
                        <Col lg={12}>
                          <Card className="card-bordred">
                            <CardBody>
                              <Row className="mt-3 ">
                                <Col lg={6}>
                                  <Row className="mt-3 ">
                                    <Col lg={12}>
                                      <label className="form-label">
                                        {t("officeType")}
                                      </label>
                                      <select
                                        className="form-select"
                                        value={officeType}
                                        onChange={(e) =>
                                          setOfficeType(e.target.value)
                                        }
                                      >
                                        <option
                                          aria-selected="true"
                                          role="option"
                                          value="0"
                                          disabled
                                        >
                                          {t("selectOfficeType")}
                                        </option>
                                        <option value="pharmacy">
                                          {t("pharmacy")}
                                        </option>
                                        <option value="hospital">
                                          {t("hospital")}
                                        </option>
                                        <option value="clsc">
                                          {t("CLSC")}
                                        </option>
                                        <option value="other">
                                          {t("other")}
                                        </option>
                                      </select>
                                    </Col>
                                  </Row>
                                  <Row className="mt-3 ">
                                    <Col lg={12}>
                                      <label className="form-label">
                                        {t("logoName")}
                                      </label>
                                      <input
                                        name="logoName"
                                        className="form-control"
                                        type="text"
                                        placeholder={t("someLogo")}
                                        value={formData.shortName}
                                        onChange={(e) =>
                                          setFormData({
                                            ...formData,
                                            shortName: e.target.value,
                                          })
                                        }
                                      />
                                        {logoNameError && (
                                <code className="text-danger">
                                  {" "}
                                  {t("logoNameRequired")}
                                </code>
                              )}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={6}>
                                  <Card>
                                    <CardBody>
                                      <label className="form-label">
                                        {t("Logo")}
                                      </label>
                                      <Form className="dropzone">
                                        <Dropzone
                                          onDrop={(acceptedFiles) =>
                                            handleAcceptedFiles(acceptedFiles)
                                          }
                                          accept={{
                                            "image/*": [],
                                          }}
                                          maxFiles={1}
                                        >
                                          {({
                                            getRootProps,
                                            getInputProps,
                                          }) => (
                                            <div className="dropzone">
                                              <div
                                                style={{ textAlign: "center" }}
                                              >
                                                <div
                                                  className="dz-message needsclick"
                                                  {...getRootProps()}
                                                >
                                                  <input {...getInputProps()} />
                                                  <div className="mb-3 ">
                                                    <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                                  </div>
                                                  <h4> {t("uloadFiles")}</h4>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </Dropzone>
                                      </Form>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                              <Row>
                                {selectedFiles && (
                                  <div className="mt-3 text-center">
                                    <img
                                      src={selectedFiles.preview}
                                      alt={selectedFiles.name}
                                      style={{
                                        maxWidth: "100%",
                                        height: "auto",
                                      }}
                                    />
                                    <ul className="list-group mt-3">
                                      <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {selectedFiles.name}
                                        <button
                                          className="btn btn-danger btn-sm"
                                          onClick={() => handleRemoveFile()}
                                        >
                                          {t("delete")}
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row className="mt-3 ">
                        <Col lg={12}>
                          <Card className="card-bordred">
                            <CardSubtitle className="form-header-qcom">
                              {t('permission')}
                            </CardSubtitle>
                            <CardBody>
                              <Row className="mt-3 ">
                                <Col>
                                <label className="form-label">
                                        {t("permissionQuessCompounding")}
                                      </label> 
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          checked={quessCompounding}
                                          onClick={handleToggle}
                                            
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                      
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={12} className="fixed-bottom-buttons">
                  <div className="float-end">
                    <button type="submit" className="ms-1 btn btn-success me-3">
                      {t("save")}
                    </button>
                    <button
                      type="reset"
                      className="btn btn-info"
                      onClick={() => goBack()}
                    >
                      {t("cancel")}
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
            {addGroupError && (
            <div
              style={{
                position: 'fixed',
                bottom: '10px',
                left: '50%',
                transform: 'translateX(-50%)',
                padding: '10px 20px',
                backgroundColor: addGroupError.type === 'success' ? 'green' : '#ff3d60',
                color: 'white',
                borderRadius: '5px',
                zIndex: 1000,
                minWidth:"25%",
                textAlign:'center',
                transition: 'opacity 0.5s ease-in-out',
              }}
            >
              {addGroupError.text}
            </div>
          )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateNewGroup;
