import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from 'reactstrap';

const AddUnitModal = ({ isOpen, toggle, handleAddUnit, newUnit, setNewUnit, alert, message }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} centered className="modal-lg">
            <ModalHeader>
                Add New Unit
            </ModalHeader>
            <ModalBody>
                {alert && alert === "danger" ? (<Alert color={alert}>{message}</Alert>) : null}
                <form onSubmit={handleAddUnit}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Unit Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={newUnit.name}
                            onChange={(e) => setNewUnit({ ...newUnit, name: e.target.value })}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="shorthand" className="form-label">Unit Shorthand</label>
                        <input
                            type="text"
                            className="form-control"
                            id="shorthand"
                            name="shorthand"
                            value={newUnit.shorthand}
                            onChange={(e) => setNewUnit({ ...newUnit, shorthand: e.target.value })}
                            required
                        />
                    </div>
                    <ModalFooter>
                        <Button type="button" color="secondary" onClick={toggle}>Cancel</Button>
                        <Button type="submit" color="primary">Add Unit</Button>
                    </ModalFooter>
                </form>
            </ModalBody>
        </Modal>
    );
};

export default AddUnitModal; 