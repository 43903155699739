import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from 'reactstrap';

const AddManufacturerModal = ({ isOpen, toggle, handleAddManufacturer, newManufacturer, setNewManufacturer, alert, message }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} centered className="modal-lg">
            <ModalHeader>
                Add New Manufacturer
            </ModalHeader>
            <ModalBody>
                {alert && alert === "danger" ? (<Alert color={alert}>{message}</Alert>) : null}
                <form onSubmit={handleAddManufacturer}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name </label>
                        <input   type="text"  className="form-control" id="name"   name="name"  value={newManufacturer.name}
                            onChange={(e) => setNewManufacturer({ ...newManufacturer, name: e.target.value })}  required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="shortName" className="form-label">Short Name</label>
                        <input  type="text"    className="form-control" id="shortName"  name="shortName" value={newManufacturer.shortName}
                            onChange={(e) => setNewManufacturer({ ...newManufacturer, shortName: e.target.value })} required
                        />
                    </div>
                    <ModalFooter>
                        <Button type="button" color="secondary" onClick={toggle}>Cancel</Button>
                        <Button type="submit" color="primary">Add Manufacturer</Button>
                    </ModalFooter>
                </form>
            </ModalBody>
        </Modal>
    );
};

export default AddManufacturerModal; 