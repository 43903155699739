import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Form,
  Col,
  Row,
  CardSubtitle,
  Alert,
  Container,
  Progress,
} from "reactstrap";
//import './style.css';
import Breadcrumbs from "../../../components/Common/Breadcrumb.js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate, useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import axios from "axios";
import { BACKEND_URL } from "../../../config/const.js";
import { useTranslation } from "react-i18next";

const UpdateUser = () => {
  const { t } = useTranslation();

  document.title = t("updateUserTitle");
  const [selectedFiles, setselectedFiles] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]); // Store the selected listOfGroups
  const [listOfGroup, setListOfGroups] = useState([]); // Store the currently selected listOfGroup
  const [organizations, setOrganization] = useState([]);
  const [originalOrganizations, setOriginalOrganisations] = useState([]);
  const [listRoleOfGroup, setListRoleOfGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [originalPassword, setOriginalPassword] = useState("");
  const { userId } = useParams();
  const [changedSignature, setChangedSignature] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    value: 0,
    text: "",
    color: "",
  });
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordChange, setPasswordChange] = useState(false);
  const [userNameError, setUserNameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameEroor, setLastNameError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [validEmailError, setValidEmailError] = useState(null);
  const [userAddedMessage, setUserAddedMessage] = useState(null);
  const [userNameExistsError, setUserNameExistsError] = useState(null);

  const [emailExistsError, setEmailExistsError] = useState(null);
  const [selectedGroupsError, setSelectedGroupsError] = useState(null);

  const handleRemoveFile = () => {
    // Remove the selected file from the state
    setChangedSignature(true);

    setselectedFiles(null);
  };

  const [formData, setFormData] = useState({
    id: "",
    username: "",
    lastname: "",
    firstname: "",
    password: "",
    email: "",
    phone: "",
    isEnabled: true,
    isAvailable: true,
    has2Fa: false,
    passwordChange: true,
    listOfGroups: listOfGroup,
    signature: null,
    listRoleOfGroup: listRoleOfGroup,
    filesNames: selectedFiles, // Attribut supplémentaire pour les fichiers
    language: localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en",
    currentOrg: localStorage.getItem("currentOrg"),
  });
  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const userRoles = await axios.get(
          `${BACKEND_URL}api/user/RolesByAffectedOrganisation/` + userId,
          config
        );
        var groups = [];
        if (userRoles.length > 0) {
          const options = userRoles.map((org) =>
            org.roles
              .filter((role) => role.checked) // Filter only checked roles
              .map((role) => ({
                groupId: org.id,
                itemId: role.id,
              }))
          );
          var groups = userRoles.map((org) => org.id);
          // Flatten the result to avoid arrays inside arrays
          var flattenedOptions = options.flat();

          setListRoleOfGroup(flattenedOptions);

          setSelectedGroups(userRoles);
        }
        const userInfos = await axios.get(
          `${BACKEND_URL}api/user/` + userId,
          config
        );
        if (userInfos.signature != "" && userInfos.signature != null) {
          setselectedFiles({
            name: userInfos.signature,
            preview: BACKEND_URL + userInfos.signatureUrl,
          });
        }

        const response = await axios.get(
          `${BACKEND_URL}api/user/getGroupsNotAffected/` + userId,
          config
        );
        if (response.length > 0) {
         
          // Assuming response.data is an array of organizations
          const options = response.map((org) => ({
            name: org.name,
            id: org.id,
            roles: org.roles,
          }));

          setOrganization(options);
        }
        const response1 = await axios.get(
          `${BACKEND_URL}api/user/getGroupsNotAffected/` + null,
          config
        );
        const has2Fa = await axios.get(
          `${BACKEND_URL}api/user/has2FA/` + userId,
          config
        );
        if (response.length > 0) {
          const options2 = response1.map((org) => ({
            name: org.name,
            id: org.id,
            roles: org.roles,
          }));
          setOriginalOrganisations(options2);
        }
        const groupsToadd=[...groups,response[0].id]
        setListOfGroups(groupsToadd);

        setFormData((prevFormData) => ({
          ...prevFormData, // Spread the previous formData
          id: userInfos.id,
          email: userInfos.email,
          firstname: userInfos.firstname,
          lastname: userInfos.lastname,
          phone: userInfos.phone,
          username: userInfos.username,
          signature: userInfos.signature,
          passwordChange: userInfos.password_change,
          isAvailable:userInfos.is_available,
          listOfGroups: groups,
          has2Fa:has2Fa.data,
          listRoleOfGroup: flattenedOptions,
        }));
        setOriginalPassword("");

        setLoading(false);
      } catch (error) {
        setError("Failed to load organizations");
        setLoading(false);
      }
    };

    fetchOrganization();
  }, [userId]);
  console.log(formData);
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const addGroup = () => {
    const selectedGroup = organizations.find(
      (g) => g.id === parseInt(listOfGroup[listOfGroup.length - 1])
    );
    console.log(listOfGroup);
    if (
      selectedGroup &&
      !selectedGroups.find((g) => g.id === selectedGroup.id)
    ) {
      setSelectedGroups([...selectedGroups, selectedGroup]);
      setFormData({
        ...formData,
        listOfGroups: [...formData.listOfGroups, selectedGroup.id],
      });
      setOrganization(organizations.filter((g) => g !== selectedGroup));
      if( organizations.filter((g) => g !== selectedGroup).length>0)
      {
        setListOfGroups([
          ...(listOfGroup || []),
         
             organizations.filter((g) => g !== selectedGroup)[0].id
            
        ]);
      }
    
    }
  };

  const removeGroup = (listOfGroupId) => {
    console.log(selectedGroups.filter((g) => g.id !== listOfGroupId));

    const selectedGroup = originalOrganizations.find(
      (g) => g.id === listOfGroupId
    );

    setSelectedGroups(selectedGroups.filter((g) => g.id !== listOfGroupId));
    setFormData({
      ...formData,
      listOfGroups: formData.listOfGroups.filter((g) => g!== listOfGroupId),
    });
    setOrganization([...organizations, selectedGroup]);
  };
  console.log(organizations);
  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    // Store only the first file (for single file upload)
    setChangedSignature(true);
    setselectedFiles(files[0]);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const navigate = useNavigate();

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    let text = t("veryWeak");
    let color = "dark";

    // Check length
    const isLongEnough = password.length >= 4;

    if (!isLongEnough) {
      // If password is too short
      setPasswordStrength({ value: 0, text: t("veryWeak"), color: "dark" });
      return;
    }

    // Criteria checks
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    const criteriaCount = [
      hasUpperCase,
      hasLowerCase,
      hasDigit,
      hasSpecialChar,
    ].filter(Boolean).length;

    switch (criteriaCount) {
      case 4:
        strength = 100;
        text = t("strong");
        color = "success";
        break;
      case 3:
        strength = 75;
        text = t("good");
        color = "info";
        break;
      case 2:
        strength = 50;
        text = t("fair");
        color = "warning";
        break;
      case 1:
        strength = 25;
        text = t("weak");
        color = "danger";
        break;
      default:
        strength = 0;
        text = t("veryWeak");
        color = "light";
    }

    setPasswordStrength({ value: strength, text, color });
  };
  useEffect(() => {
    console.log(formData.password)
    calculatePasswordStrength(formData.password);
    checkPasswordMatch(formData.password, formData.passwordConfirmation);
  }, [formData.password, formData.passwordConfirmation]);

  const handlePasswordConfirmationChange = (e) => {
    setFormData({ ...formData, passwordConfirmation: e.target.value });
  };
  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setFormData({ ...formData, password });
    calculatePasswordStrength(password);
  };
  const handleChangeGroupRole = (groupId, roleId) => {
    // Find the group-role pair that matches the passed groupId and roleId
    const groupRole = listRoleOfGroup.find(
      (el) => el.groupId === groupId && el.itemId === roleId
    );

    if (groupRole) {
      // Remove the group-role pair if it exists
      const updatedListRoleOfGroup = listRoleOfGroup.filter(
        (el) => !(el.groupId === groupId && el.itemId === roleId)
      );
      setListRoleOfGroup(updatedListRoleOfGroup);
    } else {
      // Add the new group-role pair if it doesn't exist
      setListRoleOfGroup([...listRoleOfGroup, { groupId, itemId: roleId }]);
    }
    const updatedGroups = selectedGroups.map((group) => {
      if (group.id == groupId) {
        // Update the specific group
        return {
          ...group,
          roles: group.roles.map((role) => {
            if (role.id == roleId) {
              // Update the specific role
              return {
                ...role,
                checked: !role.checked, // Toggle the checked state
              };
            }
            return role; // Keep other roles unchanged
          }),
        };
      }
      return group; // Keep other groups unchanged
    });

    // Now you can set the updated groups
    setSelectedGroups(updatedGroups);
  };
  function sanitizeFileName(originalFileName) {
    return originalFileName.replace(/\//g, " "); // Replace all slashes with a blank space
  }
  const checkUserName = () => {
    if (formData.username == "") {
      setUserNameError(true);
      return false;
    } else {
      setUserNameError(null);
      return true;
    }
  };

  const checkUserNameExists = async (username,idUser)=>{
    const userExists = await axios.get(
      `${BACKEND_URL}api/user/check/`+
      username+`/`+idUser,
      config
    );
      return userExists.exists
  }
  const checkEmailExists = async (email,idUser)=>{
    const userExists = await axios.get(
      `${BACKEND_URL}api/user/checkEmail/`+
      email+`/`+idUser,
      config
    );
      return userExists.exists
  }
  const checkUserNameInDb=async ()=> {
  const  exists= await checkUserNameExists(formData.username,formData.id)

    if(exists)
    {
      setUserNameExistsError(true)
      return exists
    }
    else {
      setUserNameExistsError(null)
      return exists
    }
      
  }
  const checkSelectedGroups = () => {
    if (selectedGroups.length == 0) {
      setSelectedGroupsError(true);
      return false;
    } else {
      setSelectedGroupsError(null);
      return true;
    }
  };
  const checkEmailInDb=async ()=> {
   if(formData.email!='')
   {
    console.log(formData.email)
    const  exists= await checkEmailExists(formData.email,formData.id)
   
    if(exists)
    {
      setEmailExistsError(true)
      return exists
    }
    else {
      setEmailExistsError(null)
      return exists
    }
   }
   else {
    return false;
   }
    
        
    }
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic regex for email validation
    return re.test(email);
  };

  const checkPassword = () => {
    if(!passwordChange)
    {
      return true;
    }
    if (formData.password == "") {
      setPasswordError(true);
      return false;
    } else {
      setPasswordError(null);
      return true;
    }
  };
  const checkFirstName = () => {
    if (formData.firstname == "") {
      setFirstNameError(true);
      return false;
    } else {
      setFirstNameError(null);
      return true;
    }
  };
  const checkLastName = () => {
    if (formData.lastname == "") {
      setLastNameError(true);
      return false;
    } else {
      setLastNameError(null);
      return true;
    }
  };
  const checkPhone = () => {
    if (formData.phone == "") {
      setPhoneError(true);
      return false;
    } else {
      setPhoneError(null);
      return true;
    }
  };
  const checkEmail = () => {
    if (formData.email == "") {
      setEmailError(true);
      return false;
    } else {
      setEmailError(null);
      return true;
    }
  };
  const checkValidEmail = () => {
    if (formData.email == "") {
      setValidEmailError(null);
      return true;
    } else if (!validateEmail(formData.email)) {
      setValidEmailError(true);
      return false;
    } else {
      setValidEmailError(null);
      return true;
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const isUserNameValid = checkUserName();
    const isFirstNameValid = checkFirstName();
    const isLastNameValid = checkLastName();
    const isPasswordValid = checkPassword();
    const isEmailValid = checkEmail();
    console.log(isEmailValid)
    const isPhoneValid = checkPhone();
    const isEmailFormatValid = checkValidEmail();
    const isPasswordMatch = passwordMatch === true;
    const emailExistsInDb= isEmailValid?await  checkEmailInDb():true;
    const groupsNotEmpty = checkSelectedGroups();
    const userNameExistsInDb=await  checkUserNameInDb()

    if (
      !isUserNameValid ||
      !isFirstNameValid ||
      !isLastNameValid ||
      !isPasswordValid ||
      !isEmailValid ||
      !isPhoneValid ||
      !isEmailFormatValid ||
      !isPasswordMatch ||
      userNameExistsInDb ||
      !groupsNotEmpty ||
      emailExistsInDb
    ) {
      setUserAddedMessage({ text: t("failureUpdateUser"), type: "error" });
      setTimeout(() => {
        setUserAddedMessage(null);
        setLoading(null);
      }, 2500);
    } else {
      const userData = {
        id: formData.id,
        username: formData.username,
        lastname: formData.lastname,
        firstname: formData.firstname,
        password: formData.password,
        email: formData.email,
        phone: formData.phone,
        isEnabled: formData.isEnabled,
        isAvailable: formData.isAvailable,
        has2Fa: formData.has2Fa,
        reason: formData.reason,
        other: formData.other,
        passwordChange: formData.passwordChange,
        listOfGroup: formData.listOfGroups.filter((group) => group !== ""),
        listRoleOfGroup: listRoleOfGroup, // Pas besoin de JSON.stringify
        filesNames: selectedFiles ? sanitizeFileName(selectedFiles.name) : "",
        language: formData.language,
        currentOrg: formData.currentOrg,
        signature: formData.signature ? formData.signature : null, // Gérer les fichiers si besoin
      };
      const configUpload = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const uplaodData = new FormData();
      uplaodData.append("file", selectedFiles);
      try {
        const response = await axios.post(
          `${BACKEND_URL}api/user/save`,
          userData,
          config
        );
        if (changedSignature) {
          const fileUploaded = await axios.post(
            `${BACKEND_URL}api/user/uploadSignature`,
            uplaodData,
            configUpload
          );
          console.log(
            "User signature uploaded successfully:",
            fileUploaded.data
          );
        }

        setLoading(false);
        
        if (response.code === 200) {
          console.log("success")
          setUserAddedMessage({
            text: t("successUpdateUser"),
            type: "success",
          });
          setTimeout(() => {
            setUserAddedMessage(null);
            setLoading(null);
            navigate("/users-management");
          }, 2500);
        } else {
          setUserAddedMessage({ text: t("failureUpdateUser"), type: "error" });
          setTimeout(() => {
            setUserAddedMessage(null);
            setLoading(null);
          }, 2500);
        }
       
      } catch (error) {
        setLoading(false);
        console.error("Error saving user:", error);
      }
    }
  };
  const checkPasswordMatch = (password, passwordConfirmation) => {
    if(password!="")
    {
      setPasswordMatch(password === passwordConfirmation);

    }
  };

  const goBack = () => {
    const source= localStorage.getItem('source');
    if(source==="listUsers")
    {
      navigate("/users-management"); // Redirect after successful creation
    }
    else {
      navigate("/update-group/"+localStorage.getItem('groupId')); 
      localStorage.removeItem('groupId')
    }
    localStorage.removeItem('source')
  };
  const cancelPasswordChange = () => {
    console.log(originalPassword)
    setPasswordChange(!passwordChange);
    setFormData((prevFormData) => ({
      ...prevFormData, // Spread the previous form data
      password: originalPassword, // Update the password field
    }));
  };

  const handlePhoneInput = (value) => {
    // Remove all non-digit characters
    const cleaned = value.replace(/\D/g, "").slice(0, 10); // Limit to 10 digits
  
    // Format the input as (XXX) XXX-XXXX
    const formatted = cleaned
      .replace(/^(\d{3})(\d{0,3})(\d{0,4})$/, (_, p1, p2, p3) => {
        let result = `(${p1}`;
        if (p2) result += `) ${p2}`;
        if (p3) result += `-${p3}`;
        return result;
      });
  
    // Update formData with the formatted phone number
    setFormData({ ...formData, phone: formatted });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={t("user")} breadcrumbItem={t("updateUser")} />
          {loading ? (
            <Alert color="info">{t("loadingSubmission")}</Alert>
          ) : error ? (
            <Alert color="danger">{error}</Alert>
          ) : null}
          <div className=" mb-4">
            <div
              style={{
                margin: "1%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                className="btn btn-primary"
                title={t("goBack")}
                onClick={() => goBack()}
              >
                <i className="fas fa-arrow-left"></i>
              </button>
            </div>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col lg={12}>
                  <Card className="card-bordred">
                    <h5 className="card-header">{t("userInfos")}</h5>
                    <CardBody>
                      <Row className="mt-3">
                        <Col lg={4}>
                          <label className="form-label">{t("username")}</label>
                          <input
                            name="username"
                            className="form-control"
                            type="text"
                            placeholder={t("username")}
                            value={formData.username}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                username: e.target.value,
                              })
                            }
                          />
                          {userNameError && (
                            <code className="text-danger">
                              {" "}
                              {t("userNameRequiredMessage")}
                            </code>
                            
                          )}
                          {userNameExistsError && (
                            <code className="text-danger">
                              {" "}
                              {t("userNameExistsMessage")}
                            </code>
                          )}
                        </Col>
                        <Col lg={4}>
                          <label className="form-label">{t("lastName")}</label>
                          <input
                            name="lastname"
                            className="form-control"
                            type="text"
                            placeholder={t("lastName")}
                            value={formData.lastname}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                lastname: e.target.value,
                              })
                            }
                          />
                          {lastNameEroor && (
                            <code className="text-danger">
                              {" "}
                              {t("lastNameRequired")}
                            </code>
                          )}
                        </Col>
                        <Col lg={4}>
                          <label className="form-label">{t("firstName")}</label>
                          <input
                            name="firstname"
                            className="form-control"
                            type="text"
                            placeholder={t("firstName")}
                            value={formData.firstname}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                firstname: e.target.value,
                              })
                            }
                          />
                          {firstNameError && (
                            <code className="text-danger">
                              {" "}
                              {t("firstNameRequired")}
                            </code>
                          )}
                        </Col>
                      </Row>
                      {passwordChange && (
                        <div>
                          <Row className="mt-3">
                            <Col lg={4}>
                              <label className="form-label">
                                {t("password")}
                              </label>
                              <input
                                name="password"
                                className="form-control"
                                type="password"
                                value={formData.password}
                                onChange={handlePasswordChange}
                              />
                              {passwordError && (
                                <code className="text-danger">
                                  {" "}
                                  {t("passwordRequiredMessage")}
                                </code>
                              )}
                            </Col>
                            <Col lg={4}>
                              <label className="form-label">
                                {t("passwordConfirm")}
                              </label>
                              <input
                                name="passwordConfirmation"
                                className="form-control"
                                type="password"
                                value={formData.passwordConfirmation}
                                onChange={handlePasswordConfirmationChange}
                              />
                              {!passwordMatch && (
                                <code className="text-danger">
                                  {" "}
                                  {t("passwordDontMatch")}
                                </code>
                              )}
                            </Col>
                            <Col lg={passwordMatch?3: 4}>
                              <div
                                className={`progress-label text-${passwordStrength.color} border-${passwordStrength.color} mb-2`}
                              >
                                {passwordStrength.text}
                              </div>
                              <Progress
                                striped
                                color={passwordStrength.color}
                                value={passwordStrength.value}
                                id="strongpassword"
                              />
                            </Col>
                            <Col lg={passwordMatch?1:0} style={{marginTop:"1%"}}>
                        { passwordMatch &&<span class="mdi mdi-checkbox-marked-circle-outline" style={{fontSize:"28px",color:"green"}}></span>}

                        </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col lg={4}>
                              <button
                                className="ms-1 btn btn-primary me-3"
                                onClick={() => cancelPasswordChange()}
                              >
                                {t("cancelChangePassword")}
                              </button>
                            </Col>
                          </Row>
                        </div>
                      )}
                      {!passwordChange && (
                        <Row className="mt-3">
                          <Col lg={4}>
                            <button
                              className="ms-1 btn btn-primary me-3"
                              onClick={() => setPasswordChange(!passwordChange)}
                            >
                              {t("changePassword")}
                            </button>
                          </Col>
                        </Row>
                      )}
                      <Row className="mt-3">
                        <Col lg={4}>
                          <label className="form-label">{t("email")}</label>
                          <input
                            name="email"
                            className="form-control"
                            type="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              })
                            }
                          />
                          {emailError && (
                            <code className="text-danger">
                              {" "}
                              {t("emailRequired")}
                            </code>
                          )}
                          {validEmailError && (
                            <code className="text-danger">
                              {" "}
                              {t("errorEmail")}
                            </code>
                          )}
                           {emailExistsError && (
                            <code className="text-danger">
                              {" "}
                              {t("emailExistsErrorMessage")}
                            </code>
                          )}
                        </Col>
                        <Col lg={4}>
                          <label className="form-label">{t("phone")}</label>
                          <input
                                name="phone"
                                className="form-control"
                                type="text" // Use text instead of number for formatting
                                placeholder={"(123) 456-7899"} // e.g., "Enter phone number"
                                value={formData.phone}
                                onChange={(e) => handlePhoneInput(e.target.value)}
                              />
                          {phoneError && (
                            <code className="text-danger">
                              {" "}
                              {t("phoneRequired")}
                            </code>
                          )}
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col lg={4}>
                          <div className="form-check">
                            <input
                              name="isEnabled"
                              className="form-check-input"
                              type="checkbox"
                              checked={formData.isAvailable}
                              onClick={() =>
                                setFormData({
                                  ...formData,
                                  isAvailable: !formData.isAvailable,
                                })
                              }
                            />
                            <label className="form-check-label">
                              {t("activateAccess")}
                            </label>
                          </div>
                        </Col>

                    
                        <Col lg={4}>
                          <div className="form-check">
                            <input
                              name="activateMFA"
                              className="form-check-input"
                              type="checkbox"
                              checked={formData.has2Fa}
                              onClick={() => {
                                setFormData((prevState) => ({
                                  ...prevState,
                                  has2Fa: !prevState.has2Fa,
                                }));
                              }}
                            />
                            <label className="form-check-label">
                              {t("activateMFA")}
                            </label>
                          </div>
                        </Col>
                      </Row>

                      {/* Signature Upload */}
                      <Row>
                        <Col className="col-12">
                          <Card>
                            <CardBody>
                              <CardSubtitle>Signature:</CardSubtitle>
                              <Form className="dropzone">
                                <Dropzone
                                  onDrop={(acceptedFiles) =>
                                    handleAcceptedFiles(acceptedFiles)
                                  }
                                  accept={{
                                    "image/*": [],
                                  }}
                                  maxFiles={1}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone">
                                      <div style={{ textAlign: "center" }}>
                                        <div
                                          className="dz-message needsclick"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div className="mb-3 ">
                                            <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                                          </div>
                                          <h4> {t("uloadFiles")}</h4>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                                {selectedFiles && (
                                  <div className="mt-3 text-center">
                                    <img
                                      src={selectedFiles.preview}
                                      alt={selectedFiles.name}
                                      style={{
                                        maxWidth: "100%",
                                        height: "auto",
                                      }}
                                    />
                                    <ul className="list-group mt-3">
                                      <li className="list-group-item d-flex justify-content-between align-items-center">
                                        {selectedFiles.name}
                                        <button
                                          className="btn btn-danger btn-sm"
                                          onClick={() => handleRemoveFile()}
                                        >
                                          {t("delete")}
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </Form>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      {organizations.length > 0 ? (
                        <Row className="mt-3">
                          <Col lg={3}>
                            <div className="form-check form-check-end">
                              {selectedGroups.length === 0 ? (
                                <label className="form-check-label text-danger">
                                  {t("addGroup")}
                                </label>
                              ) : (
                                <label className="form-check-label text-info">
                                  {t("addAnotherGroup")}
                                </label>
                              )}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <select
                              className="form-select"
                              name="listOfGroup"
                              onChange={(e) => setListOfGroups(e.target.value)}
                            >
                              <option
                                aria-selected="true"
                                role="option"
                                value="0"
                                disabled
                              >
                                {t("selectGroup")}
                              </option>
                              {organizations.map((listOfGroup) => (
                                <option
                                  key={listOfGroup.id}
                                  value={listOfGroup.id}
                                >
                                  {listOfGroup.name}
                                </option>
                              ))}
                            </select>
                            {selectedGroupsError && (
                            <code className="text-danger">
                              {" "}
                              {t("groupsRequired")}
                            </code>
                          )}
                          </Col>
                          <Col lg={3}>
                            <div className="form-check text-center">
                              <button
                                className="btn btn-outline-info waves-effect waves-light"
                                style={{ borderRadius: "50%" }}
                                type="button"
                                onClick={addGroup}
                              >
                                <i className="fa fa-plus" />
                              </button>
                            </div>
                            
                          </Col>
                        </Row>
                      ) : (
                        <Row className="mt-3"></Row>
                      )}

                      {selectedGroups.map((selectedGroup) => (
                        <Row
                          className="mt-3 row-form"
                          id={`listOfGroupId-${selectedGroup.id}`}
                          key={selectedGroup.id}
                        >
                          <Col lg={3}>
                            <div className="form-check form-check-end">
                              <label className="form-check-label text-succes">
                                {selectedGroup.name}
                              </label>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <h5 className="font-size-14 mb-3">
                              <u>{t("quessCompounding")}</u>
                            </h5>

                            {selectedGroup.roles.map((role) => {
                              return (
                                <div
                                  className="form-check form-check"
                                  key={role.id}
                                >
                                  <input
                                    value={role.id}
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`inlineCheck-${role.id}`}
                                    checked={role.checked}
                                    onClick={() =>
                                      handleChangeGroupRole(
                                        selectedGroup.id,
                                        role.id
                                      )
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`inlineCheck-${role.id}`}
                                  >
                                    {t(role.name)}
                                  </label>
                                </div>
                              );
                            })}

                            {/* Add other checkboxes as needed */}
                          </Col>
                          <Col lg={3}>
                            <div className="form-check text-center">
                              <button
                                className="btn btn-outline-danger"
                                style={{ borderRadius: "50%" }}
                                type="button"
                                onClick={() => removeGroup(selectedGroup.id)}
                              >
                                <i className="fa fa-minus" />
                              </button>
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </CardBody>
                  </Card>
                </Col>

                <Col lg={12} className="fixed-bottom-buttons">
                  <div className="float-end">
                    <button type="submit" className="ms-1 btn btn-success me-3">
                      {t("save")}
                    </button>
                    <button
                      type="reset"
                      className="btn btn-info"
                      onClick={() => goBack()}
                    >
                      {t("cancel")}
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
            {userAddedMessage && (
              <div
                style={{
                  position: "fixed",
                  bottom: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  padding: "10px 20px",
                  backgroundColor:
                    userAddedMessage.type === "success" ? "green" : "#ff3d60",
                  color: "white",
                  borderRadius: "5px",
                  zIndex: 1000,
                  minWidth: "25%",
                  textAlign: "center",
                  transition: "opacity 0.5s ease-in-out",
                }}
              >
                {userAddedMessage.text}
              </div>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UpdateUser;
