import React, { useState, useEffect } from "react";
import { Card, CardBody, Form, Col, Row, Container, CardText, CardTitle, Input, Label,  } from "reactstrap";
import logobce from "../../assets/images/logo_bce.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import logoorganization from "../../assets/images/organization_logo.png";
import './style.css';
import { useParams } from 'react-router-dom';
import { BACKEND_URL } from "../../config/const.js";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import { QRCode } from "./QRCode.js";
const Loader = () => {
  return (
    <div className="loader-container text-center">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

const Showsheet = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  document.title = "Show Sheet | Quess Magistrale";

  const [loading, setLoading] = useState(false);
  const [sheetDetail, setSheetDetail] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [logo, setLogo] = useState(null);

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  useEffect(() => {
    const logoOrg = localStorage.getItem("logoOrg"); // Assuming logoOrg is just a URL string
  
    if (logoOrg) {
      setLogo(`${BACKEND_URL}${logoOrg}`); // Ensure this constructs a valid URL
    }

  }, [logo]);
  const fetchSheetDetail = async () => {
    setLoading(true); // Set loading to true before the request starts
    try {
      const response = await axios.get(`${BACKEND_URL}api/formula/${id}`, config);
      const data = response; // Extract data from the response object
      setSheetDetail(data);
      setIngredients(data.ingredients || []);  // Safely handle ingredients if available
      console.log('Sheet Detail:', data);
    } catch (error) {
      console.error('Error fetching sheet detail:', error);
    } finally {
      setLoading(false); // Turn off the loading state after the request completes
    }
  };

  useEffect(() => {
    if (id) {
      fetchSheetDetail();  // Only fetch details if an ID is present
    }
  }, [id]);

  if (loading || !sheetDetail) {
    return <Loader />;
  }
  const goBack=()=>{
    navigate("/List-formula-sheet"); // Redirect after successful creation
  }
  const toPDF = () => {
    const printContent = document.getElementById("pdf-print").innerHTML; // Get the content to print

    // Create a new window for printing
    const printWindow = window.open('', '_blank', 'width=800,height=600');
    printWindow.document.write(`
     <html>
        <head>
          <title>Sterile Preparation Protocol</title>
          <style>
            @page {
              size: A4;
              margin: 2cm;
            }
            
            body {
              font-family: Arial, sans-serif;
              color: #333;
              line-height: 1.5;
            }
            
            .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 2rem;
            }
            
            .logo {
              width: 120px;
              height: auto;
            }
            
            .title {
              text-align: center;
              margin: 1rem 0;
            }
            
            .protocol-info {
              margin-bottom: 1rem;
            }
            
            .status-badge {
              background: #10b981;
              color: white;
              padding: 0.25rem 0.75rem;
              border-radius: 4px;
              display: inline-block;
            }
            
            .section {
              margin: 1.5rem 0;
            }
            
            .section-title {
              background: #3fb3d6;
              color: white;
              padding: 0.5rem;
              margin-bottom: 1rem;
            }
            
            table {
              width: 100%;
              border-collapse: collapse;
              margin: 1rem 0;
            }
            
            th, td {
              border: 1px solid #ddd;
              padding: 0.5rem;
              text-align: left;
            }
            
            th {
              background: #f8f9fa;
            }
            
            @media print {
              .no-print {
                display: none;
              }
              
              body {
                -webkit-print-color-adjust: exact;
                print-color-adjust: exact;
              }
            }
          </style>
        </head>
        <body>
          <div id="print-content">
            <div class="header">
              <img src="/bce-logo.png" alt="BCE Pharma" class="logo" />
              <div>
                <h1 class="title">Compounded Sterile Preparation Protocol</h1>
                <div class="protocol-info">
                  <p>Number: SC0-181224-142743</p>
                  <p>Version: 1</p>
                </div>
              </div>
              <img src="/bce-logo.png" alt="BCE Pharma" class="logo" />
            </div>

            <h2 class="title">Sodium Chloride 0.9%</h2>
            <div style="text-align: center">
              <span class="status-badge">STATUS: Staging</span>
            </div>

            <div class="section">
              <h3 class="section-title">Header</h3>
              <table>
                <tr>
                  <td><strong>Route of administration:</strong></td>
                  <td>Subcutaneous updated</td>
                  <td><strong>Effective Date:</strong></td>
                  <td>18/12/2024</td>
                </tr>
                <tr>
                  <td><strong>Pharmaceutical form:</strong></td>
                  <td>IV Suspension</td>
                  <td><strong>Authorized by:</strong></td>
                  <td></td>
                </tr>
                <tr>
                  <td><strong>Prepared by:</strong></td>
                  <td></td>
                  <td><strong>Checked by:</strong></td>
                  <td></td>
                </tr>
              </table>
            </div>

            <div class="section">
              <h3 class="section-title">Formula</h3>
              <table>
                <thead>
                  <tr>
                    <th>Ingredients</th>
                    <th>Manufacture</th>
                    <th>Lot Number</th>
                    <th>Exp Date</th>
                    <th>Quantity</th>
                    <th>Units</th>
                    <th>Quantity Required</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>phenylephrine</td>
                    <td>Sandoz</td>
                    <td>2</td>
                    <td>2025-01-03</td>
                    <td>5</td>
                    <td>mcg</td>
                    <td>12</td>
                  </tr>
                  <tr>
                    <td>test2dd</td>
                    <td>Pfizer</td>
                    <td>2</td>
                    <td>2025-01-03</td>
                    <td>9</td>
                    <td>mcg</td>
                    <td>18</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="section">
              <h3 class="section-title">Required equipment/materials</h3>
              <p>Indicate all materials and equipment that will be required to compound the sterile preparations.</p>
              <table>
                <thead>
                  <tr>
                    <th>Equipment/Materials</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>beaker</td>
                    <td>8</td>
                  </tr>
                  <tr>
                    <td>disk filter</td>
                    <td>10</td>
                  </tr>
                </tbody>
              </table>
            </div>


             <div className="mt-8 flex flex-col items-center">
            <h3 className="text-lg font-semibold mb-4">Scan for digital version</h3>
            <QRCode value="https://example.com/protocol/SC0-181224-142743" size={200} />
          </div>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close(); // Close the document to render the content
    printWindow.focus(); // Focus on the new window
    printWindow.print(); // Call the print function
    printWindow.close(); // Close the print window after printing
};
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
        <Breadcrumbs title="Preparation Sheet" breadcrumbItem="Detail Preparation sheet" />
        <div style={{ margin: '1%', display: 'flex', justifyContent: 'flex-end' }}>
        {/* <button className="btn btn-primary" onClick={()=>toPDF()} style={{marginRight:"10px"}}>
                <i className="fas fa-file-pdf"></i> 
         </button> */}
              <button className="btn btn-primary" onClick={()=>goBack()}>
                <i className="fas fa-arrow-left"></i> 
              </button>
          
            </div>
          <div className="row-form mb-4" id="pdf-print">
   
          <Row className="mb-3 mt-2">
                      <Col md={2}>
                        <img
                          src={logobce}
                          alt="BCE Logo"
                          height="24"
                          className="auth-logo logo-dark mx-auto"
                        />
                      </Col>
                      <Col md={8} className="text-center">
                        <Label className="col-form-label">Compounded Sterile Preparation Protocol</Label>
                        <p>
                          <span>Number: {sheetDetail.protocolNumber}</span>{" "}
                          <span style={{marginLeft:"4%"}}>Version: {sheetDetail.version}</span>
                        </p>
                        <div className="col-md-12 text-center mb-2">
                          <p className="p-card">
                            <h5>{sheetDetail.productName}</h5>
                          </p>
                          <br></br>
                          <span className="bg-success p-3 text-white">STATUS: {sheetDetail.status}</span>
                        </div>
                      </Col>
                      <Col md={2}>
                  <img  src={logo?logo:logoorganization} alt="Logo Organization" height="40" className="logo-organization" />
                      </Col>
                    </Row>
            <Row>
              <Col lg={12}>
                <Card className="card-bordred">
                  <h5 className="card-header" style={{background:"#3fb3d6 !important"}}>Header</h5>
                  <CardBody>

                    {/* Details Section */}
                    <Row  >
                        <Col lg={3}>
                            <Label htmlFor="route_of-administration " className=" col-form-label ms-3"  > Route of administration: </Label>
                        </Col>
                        <Col lg={3}>
                        <p className="p-show">{sheetDetail.routeOfAdministration}</p>
                        </Col>
                        <Col lg={3}>
                          <div>
                            <Label htmlFor="effective_date " className="col-form-label ms-3"  > Effective Date: </Label>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div>
                          <p className="p-show">
                          {new Date(sheetDetail.effectiveDate).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          })}
                        </p>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-2" >
                        <Col lg={3}>
                          <div>
                            <Label htmlFor="pharmaceutical_form" className=" col-form-label ms-3"  > Pharmaceutical form: </Label>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div>
                          <p className="p-show">{sheetDetail.pharmaForm}</p>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div>
                            <Label htmlFor="authorized_by" className="col-form-label ms-3"  > Created by: </Label>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div>
                          <p className="p-show"> { sheetDetail.preparedBy&&sheetDetail.preparedBy.map((item) => ( item.username))}</p>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col lg={3}>
                          <div>
                            <Label htmlFor="prepared_by" className="col-form-label ms-3"  > Compounded by: </Label>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div>
                          <p className="p-show">  { sheetDetail.approvedBy&&sheetDetail.approvedBy.map((item) => ( item.username))}</p>
                      
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div>
                            <Label htmlFor="checked_by" className="col-form-label ms-3"  > Checked by: </Label>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div>
                          <p className="p-show"> { sheetDetail.checkedBy&&sheetDetail.checkedBy.map((item) => ( item.username))}</p>
                          </div>
                        </Col>

                      </Row >

                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                    <Card className="card-bordred">
                      <h5 className="card-header">Formula </h5>
                      <CardBody>
                      <Row className="mt-3">
                          <Col lg={3}>
                            <div className="row">
                              <label htmlFor="formula_batch" className="col-md-8 col-form-label ">Formula Batch Size:</label>
                              <div className="col-md-4">                               
                             <p className="p-show"> {sheetDetail.formulaBatchSize} </p>
                              </div>
                            </div>
                          </Col>

                          <Col lg={2}>
                            <div>
                            <p className="p-show"> {sheetDetail.formulaBtachSizeUnit} </p>
                           
                            </div>
                          </Col>

                          <Col lg={2}></Col>

                          <Col lg={3}>
                            <div className="row">
                              <label htmlFor="desired_batch" className="col-md-8 col-form-label ">Desired Batch Size:</label>
                              <div className="col-md-4">
                              <p className="p-show"> {sheetDetail.desiredBatchSize} </p>
                             
                              </div>
                            </div>
                          </Col>

                          <Col lg={2}>
                            <div>   <p className="p-show"> {sheetDetail.desiredBtachSizeUnit} </p>  </div>
                          </Col>
                        </Row>

                    {/* Ingredients Table */}
                    <Row className="mt-3">
                          <div className="table-responsive">
                            <table className="table table-bordered mb-0">
                              <thead style={{ background: "black" }}>
                                <tr id="formula">
                                  <td className="th-blue">Ingredients</td>
                                  <td className="th-blue">Manufacture</td>
                                  <td className="th-blue">Lot Number</td>
                                  <td className="th-blue">Exp Date</td>
                                  <td className="th-blue">Quantity</td>
                                  <td className="th-blue">Units</td>
                                  <td className="th-blue">Quantity Required</td>
                                </tr>
                              </thead>
                              <tbody>
                              {ingredients&&ingredients.map((ing, index) => (
                              <tr key={index}>
                                <td>  {ing.name} </td>
                                <td>  {ing.manufacture}  </td>
                                <td>   {ing.lotNumber}    </td>
                                <td>   {ing.expDate}  </td>
                                <td>  {ing.quantity}  </td>
                                <td> {ing.unit}  </td>
                                <td>  {ing.qteRequired}   </td>
                              </tr>
                            ))}
                              </tbody>
                            </table>
                          </div>
                        </Row>

                      </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                    <Card className="card-bordred">
                      <h5 className="card-header">Required equipement/materiels </h5>
                      <CardBody>
                      <Row className="mt-3">
                      <p>Indicate all materials and equipment that will be required to compound the sterile preparations.</p>
                      <div className="table-responsive">
                        <table className="table table-bordered mb-0">
                          <thead style={{ background: "black" }}>
                            <tr>
                           
                            <td className="th-blue">Equipment/Materials</td>
                            <td className="th-blue">Quantity</td>
                            </tr>
                          </thead>
                          <tbody>
                          {sheetDetail.equipements.map((equip,index) => (
                                <tr key={index} >  <td > {equip.name} </td>
                                  <td> {equip.quantity} </td>
                                </tr>
                             ) )}
                          </tbody>
                        </table>
                      </div>
                    </Row>
                      </CardBody>
                </Card>
              </Col>
              <Row>
                    <Col lg={12}>
                  {sheetDetail.ingredientsInfo?
                    <Card className="card-bordred">
                      <h5 className="card-header">Additional information on ingredients</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: sheetDetail.ingredientsInfo }}  />
                        </CardText>

                      </CardBody>
                    </Card>:<></>
                    }
                     {sheetDetail.calculations?
                    <Card className="card-bordred">
                      <h5 className="card-header">Calculations and Measurements</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: sheetDetail.calculations }}  />
             
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
                     {sheetDetail.compoundingMethod?
                    <Card className="card-bordred">
                      <h5 className="card-header">Compounding Method</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: sheetDetail.compoundingMethod }}  />
                
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
                     {sheetDetail.qualityControl?
                    <Card className="card-bordred">
                      <h5 className="card-header">Quality Control</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: sheetDetail.qualityControl }}  />
                     
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
                     {sheetDetail.packaging?
                    <Card className="card-bordred">
                      <h5 className="card-header">Packaging</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: sheetDetail.packaging }}  />
              
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
                     {sheetDetail.stabilityAndStorage?
                    <Card className="card-bordred">
                      <h5 className="card-header">Stability and Storage</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: sheetDetail.stabilityAndStorage }}  />
                  
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
                     {sheetDetail.training?
                    <Card className="card-bordred">
                      <h5 className="card-header">Training</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: sheetDetail.training }}  />
                      
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
                     {sheetDetail.refs?
                    <Card className="card-bordred">
                      <h5 className="card-header">References Consulted</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: sheetDetail.refs }}  />
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
           
                  </Col>
                  {/* <div className="mt-8 flex flex-col items-center">
            <h3 className="text-lg font-semibold mb-4">Scan for digital version</h3>
            <QRCode value="https://example.com/protocol/SC0-181224-142743" size={200} />
          </div> */}
                    </Row>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Showsheet;
