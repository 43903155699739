import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Container, Row, Modal, Input, ModalBody, Alert, ModalFooter, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from 'react-router-dom';
import axios from 'axios';
import './style.css';
import { BACKEND_URL } from "../../config/const.js";
import { useDataFetching } from "../../hooks/useDataFetching.js";
import AddIngredientModal from '../../components/Mgistrale/AddIngredientModal.js';

const ListIngredients = () => {
    const [modal_list, setmodal_list] = useState(false);
    const [modal_delete, setmodal_delete] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [newIngredient, setNewIngredient] = useState({ drugName: '', drugForm: '', concentration: '', size: '', clientId: '' });
    const [editedIngredient, setEditedIngredient] = useState({ drugName: '', drugForm: '', concentration: '', size: '' });
    const [selectedIds, setSelectedIds] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [modal_standard, setmodal_standard] = useState(false);
    const [ingredientToDelete, setIngredientToDelete] = useState(null);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [message, setMessage] = useState(null);
    const [update, setUpdate] = useState(false);

    const itemsPerPage = 20;
    const clientId = JSON.parse(localStorage.getItem("currentOrg"));
    console.log('Organization', clientId);

    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    const {
        data: ingredients,
        setData: setIngredients,
        isLoading,
        error,
        refetch: refetchIngredients
    } = useDataFetching(
        `${BACKEND_URL}api/ingredientsbyclient/${clientId}`,
        clientId,
        config
    );

    const handleEditClick = (id) => {
        const ingredient = ingredients.find(ingredient => ingredient.id === id);
        if (ingredient) {
            setEditIndex(id);
            setEditedIngredient({ ...ingredient });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedIngredient(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSave = async (id) => {
        try {
            const updatedIngredient = {
                id: editedIngredient.id,
                drugName: editedIngredient.drugName,
                drugForm: editedIngredient.drugForm,
                concentration: editedIngredient.concentration,
                size: editedIngredient.size,
            };

            const response = await axios.put(
                `${BACKEND_URL}api/ingredients/${updatedIngredient.id}`,
                updatedIngredient,
                config
            );

            if (response.data.code === 409) {
                setUpdate(true);
                setAlert('danger');
                setMessage('Error: Ingredient with the same name already exists.');
            } else {
                const updatedIngredients = ingredients.map(ingredient =>
                    ingredient.id === id ? response.data : ingredient
                );
                setIngredients(updatedIngredients);
                refetchIngredients();
                setEditIndex(null);
            }
        } catch (error) {
            setUpdate(true);
            setAlert('danger');
            setMessage(error.response?.data?.message || 'Error updating ingredient.');
            setTimeout(() => {
                setUpdate(false);
            }, 2000);
        }
    };

    const handleCancel = () => {
        setEditIndex(null);
    };

    const handleRemove = async (id) => {
        try {
            await axios.delete(`${BACKEND_URL}api/ingredients/${id}`, config);
            const updatedIngredients = ingredients.filter(ingredient => ingredient.id !== id);
            setIngredients(updatedIngredients);
            setmodal_standard(false);
            setMessage('Ingredient removed successfully...');
            setLoading(true);
            setAlert('warning');
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        } catch (error) {
            console.error('Error deleting ingredient:', error);
        }
    };

    const handleAddIngredient = async (e) => {
        e.preventDefault();
        try {
            const ingredientToCreate = { ...newIngredient, clientId: clientId };
            const response = await axios.post(`${BACKEND_URL}api/ingredients`, ingredientToCreate, config);

            if (response.data.code === 409) {
                setLoading(true);
                setAlert('danger');
                setMessage('Error: Ingredient with the same name already exists.');
            } else {
                refetchIngredients();
                // setIngredients([response.data ,...ingredients]);
                setmodal_list(false);
                setNewIngredient({ drugName: '', drugForm: '', concentration: '', size: '', clientId: ''});
           
                setMessage('Ingredient added successfully...');
                setLoading(true);
                setAlert('success');
            }

            setTimeout(() => {
                setLoading(false);
            }, 3000);
        } catch (error) {
            setLoading(true);
            setAlert('danger');
            setMessage('Error adding existing ingredient.');
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    };

    const handleCheckboxChange = (id) => {
        setSelectedIds(prevSelectedIds =>
            prevSelectedIds.includes(id)
                ? prevSelectedIds.filter(selectedId => selectedId !== id)
                : [...prevSelectedIds, id]
        );
    };

    const filteredIngredients = ingredients && ingredients.filter(ingredient => {
        if (!ingredient) return false;
        const drugForm = ingredient.drugForm ? ingredient.drugForm.toLowerCase() : '';
        const size = ingredient.size ? ingredient.size.toLowerCase() : '';
        const drugName = ingredient.drugName ? ingredient.drugName.toLowerCase() : '';

        return size.includes(searchTerm.toLowerCase()) || drugForm.includes(searchTerm.toLowerCase()) || drugName.includes(searchTerm.toLowerCase());
    });

    const totalItems = filteredIngredients&&filteredIngredients.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredIngredients&&filteredIngredients.slice(indexOfFirstItem, indexOfLastItem);

    const toggleModal = () => {
        setmodal_list(!modal_list);
        if (modal_list) {
            // Réinitialiser le formulaire lorsque le modal est fermé
            setNewIngredient({ drugName: '', drugForm: '', concentration: '', size: '', clientId: ''});
            setAlert(null);
            setMessage(null);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Management" breadcrumbItem="List Ingredient" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => setmodal_list(true)} id="create-btn">
                                                        <i className="ri-add-line align-bottom me-1"></i> Add Ingredient
                                                    </Button>

                                                </div>
                                            </Col>



                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2" style={{ display: 'flex' }}>
                                                        <i className="ri-search-line search-icon mt-1 p-2"></i>
                                                        <input
                                                            type="text"
                                                            className="form-control search"
                                                            placeholder="Search..."
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                        />

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">

                                            {loading && alert === "success" ? (<Alert color={alert}>{message}</Alert>) : null}
                                            <table className="table align-middle table-nowrap" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                    
                                                        <th className="sort" data-sort="drugForm">Drug Name</th>
                                                        <th className="sort" data-sort="concentration">Concentration/Strength</th>
                                                        <th className="sort" data-sort="drugForm">Drug Form</th>
                                                        <th className="sort" data-sort="size">Size</th>
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {update && alert === "danger" ? (
                                                        <tr>
                                                            <td colSpan={5}><Alert color={alert}>{message}</Alert></td>
                                                        </tr>
                                                    ) : null}

                                                    {currentItems && currentItems.map((ingredient) => (
                                                        <tr key={ingredient.id}>
                                                            {/* <td scope="row">
                                                                <div className="form-check">
                                                                    <input  className="form-check-input"  type="checkbox"  name="chk_child"
                                                                        value="option1"  onChange={() => handleCheckboxChange(ingredient.id)}
                                                                        checked={selectedIds.includes(ingredient.id)}
                                                                    />
                                                                </div>
                                                            </td> */}
                                                            {/* <td className="id">{ingredient.id}</td> */}
                                                            <td className="drugName">
                                                                {editIndex === ingredient.id ? (
                                                                    <Input  type="text" name="drugName"   value={editedIngredient.drugName}  onChange={handleInputChange}  />
                                                                ) : (
                                                                    ingredient.drugName
                                                                )}
                                                            </td>
                                                            <td className="concentration">
                                                                {editIndex === ingredient.id ? (
                                                                    <Input   type="text"  name="concentration"  value={editedIngredient.concentration}  onChange={handleInputChange}   />
                                                                ) : (   ingredient.concentration  )}
                                                            </td>
                                                            <td className="drugForm">
                                                                {editIndex === ingredient.id ? (
                                                                    <Input   type="text"   name="drugForm"    value={editedIngredient.drugForm}   onChange={handleInputChange}  />
                                                                ) : (
                                                                    ingredient.drugForm
                                                                )}
                                                            </td>
                                                            <td className="size">
                                                                {editIndex === ingredient.id ? (
                                                                    <Input  type="text"  name="size"  value={editedIngredient.size}  onChange={handleInputChange}  />
                                                                ) : (
                                                                    ingredient.size
                                                                )}
                                                            </td>
                                                            <td>
                                                                <div className="d-flex gap-2">
                                                                    {editIndex === ingredient.id ? (
                                                                        <>
                                                                            <button className="btn btn-sm btn-success" onClick={() => handleSave(ingredient.id)}>
                                                                                <i className="fas fa-save"></i>
                                                                            </button>
                                                                            <button className="btn btn-sm btn-danger" onClick={handleCancel}>
                                                                                <i className='dripicons-return'></i>
                                                                            </button>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <button className="btn btn-outline-info waves-effect waves-light" onClick={() => handleEditClick(ingredient.id)}>
                                                                                <i className="ri-pencil-fill align-bottom"></i>
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-outline-danger waves-effect waves-light"
                                                                                onClick={() => {
                                                                                    setIngredientToDelete(ingredient);
                                                                                    setmodal_standard(true);
                                                                                }}
                                                                                disabled={ingredient.isUsed}
                                                                            >
                                                                                <i className="ri-delete-bin-fill align-bottom"></i>
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                            {totalItems === 0 && (
                                                <div className="noresult" style={{ padding: "30px 0" }}>
                                                    <div className="text-center">
                                                        {isLoading ? (
                                                            <div className="loading-container">
                                                                <div className="loading-spinner">
                                                                    <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </div>
                                                                    <h5 className="mt-3 text-primary">Loading data...</h5>
                                                                    <p className="text-muted">Please wait while we fetch the information</p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="no-result-container">
                                                              
                                                                <h5 className="mt-3">Sorry! No Result Found</h5>
                                                          
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {totalItems > itemsPerPage && (
                                            <div className="d-flex justify-content-end mt-3">
                                                <nav>
                                                    <ul className="pagination">
                                                        {[...Array(totalPages)].map((_, pageIndex) => (
                                                            <li 
                                                                key={pageIndex} 
                                                                className={`page-item ${currentPage === pageIndex + 1 ? 'active' : ''}`}
                                                            >
                                                                <button 
                                                                    className="page-link" 
                                                                    onClick={() => handlePageChange(pageIndex + 1)}
                                                                >
                                                                    {pageIndex + 1}
                                                                </button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </nav>
                                            </div>
                                        )}

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <AddIngredientModal
                isOpen={modal_list}
                toggle={toggleModal}
                handleAddIngredient={handleAddIngredient}
                newIngredient={newIngredient}
                setNewIngredient={setNewIngredient}
                alert={alert}
                message={message}
            />

            {/*start model delete */}
            <Modal isOpen={modal_standard} toggle={() => setmodal_standard(!modal_standard)} className="modal-dialog-centered">

                <CardBody>
                    <div className="text-center">
                        <div className="mb-4">
                            <i className="mdi mdi-alert-circle-outline display-1 text-danger fs-30"></i>
                        </div>
                        <h4 className="alert-heading">
                            Are you sure?
                        </h4>
                        <p className="mb-0">
                            you won't be able to revert this !
                            <br />   <br />
                        </p>
                    </div>
                </CardBody>

                <div className="modal-footer text-center " style={{ justifyContent: "center" }}>
                    <Button color="success" onClick={() => ingredientToDelete && handleRemove(ingredientToDelete.id)}>Yes, delete it !</Button>


                    <button type="button" className="btn btn-danger " onClick={() => { setmodal_standard(false); }}  >
                        Cancel
                    </button>
                </div>
            </Modal>
            {/* end model delete */}
        </React.Fragment>
    );
};

export default ListIngredients;
