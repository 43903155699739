import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row, Modal, Input, ModalBody, Alert, ModalFooter, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from 'axios';
import './style.css';
import { BACKEND_URL } from "../../config/const.js";
import { useDataFetching } from "../../hooks/useDataFetching.js";

const ListEquipements = () => {
    const [modal_list, setmodal_list] = useState(false);
    const [modal_delete, setmodal_delete] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [newEquipement, setNewEquipement] = useState({ itemName: '', unit: '', size: '', clientId: '' });
    const [editedEquipement, setEditedEquipement] = useState({ itemName: '', unit: '', size: '' });
    const [selectedIds, setSelectedIds] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [modal_standard, setmodal_standard] = useState(false);
    const [equipementToDelete, setEquipementToDelete] = useState(null);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [message, setMessage] = useState(null);
    const [update, setUpdate] = useState(false);

    const itemsPerPage = 20;
    const clientId = JSON.parse(localStorage.getItem("currentOrg"));

    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    // Utilisation du hook useDataFetching
    const {
        data: equipements,
        setData: setEquipements,
        isLoading,
        error,
        refetch: refetchEquipements
    } = useDataFetching(
        `${BACKEND_URL}api/equipementsbyclient/${clientId}`,
        clientId,
        config
    );

    const handleEditClick = (id) => {
        const equipement = equipements.find(equipement => equipement.id === id);
        if (equipement) {
            setEditIndex(id);
            setEditedEquipement({ ...equipement });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedEquipement(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSave = async (id) => {
        try {
            const updatedEquipement = {
                id: editedEquipement.id,
                itemName: editedEquipement.itemName,
                unit: editedEquipement.unit,
                size: editedEquipement.size,
                clientId: clientId
            };

            const response = await axios.put(
                `${BACKEND_URL}api/equipements/${updatedEquipement.id}`,
                updatedEquipement,
                config
            );

            if (response.data.code === 409) {
                setUpdate(true);
                setAlert('danger');
                setMessage('Error: Equipment with the same name already exists.');
            } else {
                const updatedEquipements = equipements.map(equipement =>
                    equipement.id === id ? response.data : equipement
                );
                setEquipements(updatedEquipements);
                refetchEquipements();
                setEditIndex(null);
            }
        } catch (error) {
            setUpdate(true);
            setAlert('danger');
            setMessage(error.response?.data?.message || 'Error updating equipment.');
            setTimeout(() => {
                setUpdate(false);
            }, 2000);
        }
    };

    const toggleModal = () => {
        setmodal_list(!modal_list);
        if (modal_list) {
            // Réinitialiser le formulaire lorsque le modal est fermé
            setNewEquipement({ itemName: '', unit: '', size: '', clientId: '' });
            setAlert(null);
            setMessage(null);
        }
    };

    const handleAddEquipement = async (e) => {
        e.preventDefault();
        try {
            const equipementToCreate = { ...newEquipement, clientId: clientId };
            const response = await axios.post(`${BACKEND_URL}api/equipements`, equipementToCreate, config);

            if (response.data.code === 409) {
                setLoading(true);
                setAlert('danger');
                setMessage('Error: Equipement with the same name already exists.');
            } else {
                refetchEquipements();
                setmodal_list(false);
                setNewEquipement({itemName: '', unit: '', size: '', clientId: '' });
           
                setMessage('Equipment added successfully...');
                setLoading(true);
                setAlert('success');
                setTimeout(() => {
                    setLoading(false);
                }, 2500);
            }
        } catch (error) {
            setAlert('danger');
            setMessage('Error adding existing equipment.');
            setTimeout(() => {
                setLoading(false);
            }, 3500);
        }
    };

    const handleRemove = async (id) => {
        try {
            await axios.delete(`${BACKEND_URL}api/equipements/${id}`, config);
            const updatedEquipements = equipements.filter(equipement => equipement.id !== id);
            setEquipements(updatedEquipements);
            setmodal_standard(false);
            setMessage('Equipement removed successfully...');
            setLoading(true);
            setAlert('warning');
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        } catch (error) {
            console.error('Error deleting equipment:', error);
        }
    };

    const handleCancel = () => {
        setEditIndex(null);
    };

    const handleCheckboxChange = (id) => {
        setSelectedIds(prevIds =>
            prevIds.includes(id)
                ? prevIds.filter(selectedId => selectedId !== id)
                : [...prevIds, id]
        );
    };

    // Filtrage et pagination
    const filteredEquipements = equipements ? equipements.filter(equipement => {
        if (!equipement) return false;
        const unit = equipement.unit?.toLowerCase() || '';
        const size = equipement.size?.toLowerCase() || '';
        const itemName = equipement.itemName?.toLowerCase() || '';
        return size.includes(searchTerm.toLowerCase()) ||
               unit.includes(searchTerm.toLowerCase()) ||
               itemName.includes(searchTerm.toLowerCase());
    }) : [];

    const totalItems = filteredEquipements.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const displayedEquipements = filteredEquipements.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Management" breadcrumbItem="List Equipment/Materials" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => setmodal_list(true)} id="create-btn">
                                                        <i className="ri-add-line align-bottom me-1"></i> Add Equipment
                                                    </Button>

                                                </div>
                                            </Col>



                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2" style={{ display: 'flex' }}>
                                                        <i className="ri-search-line search-icon mt-1 p-2"></i>
                                                        <input   type="text"   className="form-control search"
                                                            placeholder="Search..."   value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                        />

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">

                                            {loading && alert === "success" ? (<Alert color={alert}>{message}</Alert>) : null}
                                            <table className="table align-middle table-nowrap" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        {/* <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="checkAll"
                                                                    value="option"
                                                                    onChange={(e) => {
                                                                        const isChecked = e.target.checked;
                                                                        setSelectedIds(isChecked ? equipements.map(i => i.id) : []);
                                                                    }}
                                                                    checked={selectedIds.length === equipements.length && equipements.length > 0}
                                                                />
                                                            </div>
                                                        </th>
                                                        <th className="sort" data-sort="id">#ID</th> */}

                                                        <th className="sort" data-sort="itemName">Equipment Name</th>
                                                        <th className="sort" data-sort="unit">Size</th>
                                                        <th className="sort" data-sort="unit">Unit</th>
                                                       
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {update && alert === "danger" ? (
                                                        <tr>
                                                            <td colSpan={5}><Alert color={alert}>{message}</Alert></td>
                                                        </tr>
                                                    ) : null}

                                                    {displayedEquipements && displayedEquipements.map((equipement) => (
                                                        <tr key={equipement.id}>
                                                            {/* <td scope="row">
                                                                <div className="form-check">
                                                                    <input  className="form-check-input"  type="checkbox"  name="chk_child"
                                                                        value="option1"  onChange={() => handleCheckboxChange(equipement.id)}
                                                                        checked={selectedIds.includes(equipement.id)}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className="id">{equipement.id}</td> */}
                                                            <td className="itemName">
                                                                {editIndex === equipement.id ? (
                                                                    <Input  type="text" name="itemName"   value={editedEquipement.itemName}  onChange={handleInputChange}  />
                                                                ) : (
                                                                    equipement.itemName
                                                                )}
                                                            </td>
                                                        
                                                            <td className="size">
                                                                {editIndex === equipement.id ? (
                                                                    <Input  type="text"  name="size"  value={editedEquipement.size}  onChange={handleInputChange}  />
                                                                ) : (
                                                                    equipement.size
                                                                )}
                                                            </td>
                                                            <td className="unit">
                                                                {editIndex === equipement.id ? (
                                                                    <Input   type="text"   name="unit"    value={editedEquipement.unit}   onChange={handleInputChange}  />
                                                                ) : (
                                                                    equipement.unit
                                                                )}
                                                            </td>
                                                            <td>
                                                                <div className="d-flex gap-2">
                                                                    {editIndex === equipement.id ? (
                                                                        <>
                                                                            <button className="btn btn-sm btn-success" onClick={() => handleSave(equipement.id)}>
                                                                                <i className="fas fa-save"></i>
                                                                            </button>
                                                                            <button className="btn btn-sm btn-danger" onClick={handleCancel}>
                                                                                <i className='dripicons-return'></i>
                                                                            </button>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <button className="btn btn-outline-info waves-effect waves-light" onClick={() => handleEditClick(equipement.id)}>
                                                                                <i className="ri-pencil-fill align-bottom"></i>
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-outline-danger waves-effect waves-light"
                                                                                onClick={() => {
                                                                                    setEquipementToDelete(equipement);
                                                                                    setmodal_standard(true);
                                                                                }}
                                                                                disabled={equipement.isUsed}
                                                                            >
                                                                                <i className="ri-delete-bin-fill align-bottom"></i>
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                            {totalItems === 0 && (
                                                <div className="noresult" style={{ padding: "30px 0" }}>
                                                    <div className="text-center">
                                                        {isLoading ? (
                                                            <div className="loading-container">
                                                                <div className="loading-spinner">
                                                                    <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </div>
                                                                    <h5 className="mt-3 text-primary">Loading data...</h5>
                                                                    <p className="text-muted">Please wait while we fetch the information</p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="no-result-container">
                                                            <h5 className="mt-3">Sorry! No Result Found</h5>
                                                                
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {totalItems > itemsPerPage && (
                                            <div className="d-flex justify-content-end mt-3">
                                                <nav>
                                                    <ul className="pagination">
                                                        {[...Array(totalPages)].map((_, pageIndex) => (
                                                            <li key={pageIndex} className={`page-item ${currentPage === pageIndex + 1 ? 'active' : ''}`}>
                                                                <button className="page-link" onClick={() => handlePageChange(pageIndex + 1)}  >
                                                                    {pageIndex + 1}
                                                                </button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </nav>
                                            </div>
                                        )}

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/*start model add*/}
            <Modal isOpen={modal_list} toggle={toggleModal} centered className="modal-lg"  >
                <ModalHeader>
                    Add New Equipment
                </ModalHeader>
                <ModalBody>
                    {alert && alert === "danger" ? (<Alert color={alert}>{message}</Alert>) : null}
                    <form onSubmit={handleAddEquipement}>

                        <div className="mb-3">
                            <label htmlFor="itemName" className="form-label">Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="itemName"
                                name="itemName"
                                value={newEquipement.itemName}
                                onChange={(e) => setNewEquipement({ ...newEquipement, itemName: e.target.value })}
                                required
                            />
                        </div>
                  
                        <div className="mb-3">
                            <label htmlFor="size" className="form-label">Size</label>
                            <input  type="text"  className="form-control" id="size" name="size"  value={newEquipement.size}
                                onChange={(e) => setNewEquipement({ ...newEquipement, size: e.target.value })} required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="unit" className="form-label">Unit</label>
                            <input
                                type="text"
                                className="form-control"
                                id="unit"
                                name="unit"
                                value={newEquipement.unit}
                                onChange={(e) => setNewEquipement({ ...newEquipement, unit: e.target.value })}
                                required
                            />
                        </div>
                        <ModalFooter>
                            <Button type="button" color="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button type="submit" color="primary">Add Equipment</Button>
                        </ModalFooter>
                    </form>
                </ModalBody>
            </Modal>
            {/*end model add */}

            {/*start model delete */}
            <Modal isOpen={modal_standard} toggle={() => setmodal_standard(!modal_standard)} className="modal-dialog-centered">

                <CardBody>
                    <div className="text-center">
                        <div className="mb-4">
                            <i className="mdi mdi-alert-circle-outline display-1 text-danger fs-30"></i>
                        </div>
                        <h4 className="alert-heading">
                            Are you sure?
                        </h4>
                        <p className="mb-0">
                            you won't be able to revert this !
                            <br />   <br />
                        </p>
                    </div>
                </CardBody>

                <div className="modal-footer text-center " style={{ justifyContent: "center" }}>
                    <Button color="success" onClick={() => equipementToDelete && handleRemove(equipementToDelete.id)}>Yes, delete it !</Button>


                    <button type="button" className="btn btn-danger " onClick={() => { setmodal_standard(!modal_standard); }}  >
                        Cancel
                    </button>
                </div>
            </Modal>
            {/* end model delete */}
        </React.Fragment>
    );
};

export default ListEquipements;
